.name-wrapper {
    border: 2px solid #94a3b8;
    border-radius: 5px;
    background-color: #475569;
    position: fixed;
    top: 50px;
    left: 25%;
    padding: 10px;
}

.player-name {
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    color: #22c55e;
    font-style: italic;
}

.player-id {
    display: inline-block;
    font-family: monospace;
    font-size: 18px;
    color: #34d399;
    margin-left: 3px;
    
}


.back-search {
    position: absolute;
    left: 10px;
    top: 10px;
    font-family: monospace;
    font-style: italic;
    /* color: #22c55e; */

    filter: invert(1);
    mix-blend-mode: difference;

    font-size: 20px;
    transition-duration: 0.2s;
    /* text-shadow: #3ee77c 3px 0px 7px; */
    border: 2px solid black;
    border-radius: 6px;
    padding: 3px 6px;
}

.back-search:hover {
    cursor: pointer;
    color: #3ee77c;
    background-color: black;
    border-radius: 8px;
    /* font-size: 21px; */

}


.player-layer-details-wrapper {
    display: grid;
    grid-template-columns: [l] 10% [l1] 40% [m] 40% [r1] 10% [r];
    margin-top: 30px;
}

.load-controller-wrapper {
    grid-column: l1 / r1;
}

.load-button {
    background-color: #475569;
    border: 2px solid #94a3b8;
    color: white;
    border-radius: 8px;
    padding: 8px;
    font-family: monospace;
    transition-duration: 0.2s;
}

.load-button:hover {
    cursor: pointer;
    color: #22c55e;
}

.details-view-switch-wrapper {
    grid-column: l1 / r1;
}

.details-view-switch-button {
    margin: 0px 3px;
    background-color: #475569;
    border: 2px solid #94a3b8;
    color: white;
    border-radius: 8px;
    padding: 8px;
    font-family: monospace;
    transition-duration: 0.2s;
    width: 88px;
}

.details-view-switch-button:hover {
    cursor: pointer;
    color: #22c55e;
}





.material-symbols-rounded {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.favorite {
    color: #ff8b07;
    font-variation-settings: 
    'FILL' 1;
}

.star {
  margin-right: 8px;
}

.star:hover {
    cursor: pointer;
}












.lds-default {
    display: inline-block;
    left: 50%;
    top: 200px;
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
      
    }
    50% {
      transform: scale(1.5);
      background-color: #22c55e;
    }
  }

