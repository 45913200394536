.roles-wrapper {
    grid-column: l1 / r1;
}

.roles-role-wrapper {
    background-color: #64748b;
    border: 2px solid #94a3b8;
    text-align: center;
    width: 25%;
    min-width: 250px;
    text-align: left;
    margin: 30px 10px;
    padding: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 3px 20px rgb(0 0 0 / 0.2);
}

.roles-role-wrapper:hover {
    cursor: text;
}

.role-stat-label {
    font-family: monospace;
    font-size: 17px;
}

.role-stat-value {
    color: #1e293b;
    float: right;
    font-style: italic;
    /* font-size: 18px; */
}

.role-stat-value:hover {
    margin-right: 3px;
}

.roles-label {
    color: #22c55e;
    font-size: 22px;
    /* border-bottom: 2px solid #1e293b; */
    display: inline-block;
    margin-bottom: 5px;
}

.stats {
    border-bottom: 1px solid #cbd5e12a;
    padding: 2px;
}

.stats:hover {
    background-color: #94a3b831;
}