body {
    background-color: #334155;
}

#root {
    height: 100%;
}

.search-wrapper {
    /* border: 3px dashed red; */
    height: 100%;
    
}

.favorite-users-wrapper {
    margin-right: 20px;
}

.user {
    color: #22c55e;
    font-family: monospace;
    text-align: center;
    margin: 2px 0px;
    font-size: 16px;
}

.user:hover {
    text-decoration: underline;
    cursor: pointer;
}

.favorite-label {
    text-align: left;
    color: #22c55e;
    font-family: monospace;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 10px;
    /* margin-right: 20px; */
    font-size: 17px;
    color: #cbd5e1;
}

.search-favorite {
    
    font-variation-settings:
    'FILL' 1,
    
}

.search-box-wrapper {
    left: 50%;
    top: 200px;
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.search-box {
   
    border: 2px solid #94a3b8;
    background-color: #64748b;
    border-radius: 5px;
    width: 25%;
    max-width: 500px;
    min-width: 300px;
    /* height: 100px; */

    display: grid;
    padding: 10px;
    grid-template-columns: [l] 50% [m] 50% [r];
    grid-template-rows: [t] 45px [m] 45px [b];
}

.search-input {
    border: 2px solid #94a3b8;
    outline: none;
    grid-column: l / r;
    height: 30px;
    border-radius: 3px;
    padding: 3px;
    padding-right: 6px;
    font-size: 18px;
    font-family: monospace;
    background-color: #475569;
    color: #cbd5e1;
    text-align: right;
    margin: 5px 0px;
    
}

.search-input::placeholder {
    color: #94a3b8;
    font-family: monospace;
    font-style: italic;
}

.search-button {
    grid-column: m / r;
    height: 40px;
    background-color: #475569;
    border: 2px solid #94a3b8;
    border-radius: 3px;
    padding: 3px;
    font-size: 20px;
    font-style: italic;
    font-family: monospace;
    transition-duration: 0.1s;
    font-weight: 600;
    color: #cbd5e1;
    margin: 5px 0px;
}

.search-button:hover {
    cursor: pointer;
    color: #22c55e;
}

.slow {
    /* border: 1px dashed red; */
    position: fixed;
    top: 25px;
    left: 100px;
    font-family: monospace;
    color: rgb(255, 170, 66);
    font-size: 22px;
}

.slow:hover {
    cursor: pointer;
}

.l-s {
    font-size: 26px;
    color: darkorange;
}

.l-l {
    font-size: 26px;
    color: darkorange;
}

.l-ow {
    font-size: 26px;
    color: darkorange;
}

@media
only screen and (max-device-width : 414px) {
    .slow {
        left: 50%;
        top: 50px;
        position: fixed;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
  
}

.info-wrapper {
    background-color: #94a3b8;
    border: 2px solid #475569;
    border-radius: 10px;
    top: 35%;
    z-index: 100;
    padding: 20px;
    font-family: monospace;
    font-style: italic;
}

.info-item {

}

.mail {
    color: #22c55e;
    text-shadow: #334155 1px 1px 2px;
}

.blur {
    filter: blur(3px);
}

.close {
    /* background-color: #475569; */
    border: 2px solid #475569;
    display: inline-block;
    border-radius: 7px;
    padding: 4px;
    margin: 7px 0px 0px 0px;
    transition-duration: 0.1s;
}

.close:hover {
    cursor: pointer;
    background-color: #64748b;
    color: white;
}

