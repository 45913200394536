.heroes-wrapper {
    grid-column: l1 / r1;
    /* margin-bottom: 50px; */
}

.heroes-input-wrapper {
    min-width: 250px;
    width: 25%;
    height: 50px;
    background-color: #64748b;
    border: 2px solid #94a3b8;
    border-top-left-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 15px;
    padding: 20px;
    margin: 20px 0px;
}

.c {
    height: 100%;
    /* margin-bottom: 50px; */
}

.heroes-list-wrapper {
    width: 25%;
    min-width: 250px;
    /* background-color: #64748b;
    border: 2px solid #94a3b8;
    border-radius: 10px; */
    height: 100%;
    margin-top: 20px;
    /* padding: 20px; */
}

.sort-button {
    width: 75px;
    height: 30px;
    border: 2px solid #cbd5e1;
    background-color: #94a3b8;
    border-radius: 3px;
    transition-duration: 0.1s;
    margin: 0px 5px;
}

.sort-button:hover {
    cursor: pointer;
    background-color: #cbd5e1;
}

.hero-wrapper {
    background-color: #64748b;
    border: 2px solid #94a3b8;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 15px; */
    border-radius: 3px;
    /* padding: 10px; */
    display: block;
    float: left;
    width: 100%;
    margin: 3px 0px;
    /* height: 32px; */
}

.hero-wrapper:hover {
    /* cursor: pointer; */
    background-color: #94a3b8;
}

.hero-details {
    /* border: 1px dashed hotpink; */
    padding: 5px;
}

.heroes-name-wrapper {
    display: block;
    width: 100%;
    height: 32px;
    padding: 5px 10px;
    /* border: 1px solid seagreen; */
    transition-duration: 0.1s;
}

.heroes-name-wrapper:hover {
    cursor: pointer;
    /* background-color: #94a3b8; */
}

.heroes-name {
    float: left;
    font-size: 19px;
    font-style: italic;
    /* margin-left: 8px; */
    color: white;
    
}

.heroes-name:hover {
    
}

.dropdown-icon {
    float: right;
    margin-right: 25px;
    margin-top: 4px;
}

.hero-stat-wrapper {
    display: block;
    width: 100%;
    height: 25px;
    /* border: 1px dashed lawngreen; */
    border-bottom: 1px solid #cbd5e12a;
    padding: 2px;
    height: 20px;
}

.hero-stat-wrapper:hover {
    background-color: #94a3b831;
}

.hero-stat-label {
    float: left;
    font-family: monospace;
    font-size: 17px;
}

.hero-stat-value {
    font-family: monospace;
    float: right;
    font-size: 16px;
    font-style: italic;
    margin-right: 5px;
}