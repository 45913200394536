.summary-namecard {
    width: 100%;
    max-height: 250px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@media
only screen and (max-device-width : 414px) {
    .summary-spread-wrapper {
        display: grid;
        grid-template-columns: [l1] 5% [l2] 32.5% [m1] 5% [m2] 52.5% [r2] 5% [r1];
        grid-template-rows: [t1] 25px [t2] 25px [t3] 100px [b3] 50px [xx] 0px [b2] 60px [b1] ;
    }

    .summary-avatar {
        height: 100px;
        border-radius: 50px;
        grid-column: l2 / m1;
        grid-row: t2 / b3;
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }

    .favorite-grid-wrapper {
        grid-column: m2 / r2;
        grid-row: t2 / t3;
    }

    .summary-text-wrapper {
        grid-column: m2 / r2;
        grid-row: t3 / b3;
    }

    .endrosement-img {
        grid-column: l2 / m1;
        grid-row: b3 / xx;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        height: 50px;
        
    }

    .summary-ranks-wrapper {
        text-align: center;
        grid-column: l2 / r2;
        grid-row: b2 / b1;
        padding: 10px;
        display: block;
        /* margin-top: 20px; */
        height: 50px;
        margin-bottom: 10px;
    }
}

@media
only screen and (min-device-width : 414px) {
    .summary-spread-wrapper {
        /* border: 2px solid red; */
        margin: 20px 50px;
    
    }
    
    .favorite-grid-wrapper {
        display: inline-block;
        
    }
    
    .summary-avatar {
        height: 100px;
        border-radius: 50px;
    
    }
    
    .summary-text-wrapper {
        display: inline-block;
        margin-left: 0px;
        margin: 0px 10px;
    }


    .endrosement-img {
        height: 50px;
        /* margin-left: 20px;
        padding-bottom: 40px; */
        margin: 0px 10px;
    }


    
    .summary-ranks-wrapper {
            /* border: 1px dashed red; */
        /* margin-left: 70px; */
            /* border: 1px solid black; */
        /* box-shadow: -5px 10px 15px rgba(182, 181, 99, 0.2);
        padding: 4px;
        border-radius: 25px;
        display: inline-block; */
            /* border-bottom: 1px solid rgb(83, 83, 83); */
    
        padding: 10px;
        display: block;
        margin-top: 20px;
    
        /* border-bottom: 1px solid rgb(0, 0, 0); */
        /* box-shadow: -5px 20px 20px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px; */
        /* width: 300px; */
    }
}

.icon-wrapper {
    display: inline-block;
    margin: 0px 4px;
    
}

.summary-title {
    font-size: 25px;
    font-style: italic;
    margin-left: 0px;
    color: #22c55e;
}


.summary-name {
    font-size: 45px;
    font-style: italic;
    
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.name-text-wrapper p {
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    display: inline-block;
}

.name-text {
    display: inline-block;
    margin: 0px;
    background: linear-gradient(45deg, #20ff50, #1affc4, #ff36ef, #ff285d, #ff822e, #dcff42);
    background-size: 300% 300%;

    -webkit-animation: nameBKG 20s ease infinite;
    -moz-animation: nameBKG 20s ease infinite;
    animation: nameBKG 20s ease infinite;
}
    
@-webkit-keyframes nameBKG {
    0%{background-position:97% 0%}
    50%{background-position:4% 100%}
    100%{background-position:97% 0%}
}
@-moz-keyframes nameBKG {
    0%{background-position:97% 0%}
    50%{background-position:4% 100%}
    100%{background-position:97% 0%}
}
@keyframes nameBKG {
    0%{background-position:97% 0%}
    50%{background-position:4% 100%}
    100%{background-position:97% 0%}
}







.summary-wrapper {
    /* border: 1px dashed blue; */
    background-color: #64748b;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding-bottom: 10px;
}

.summary-info-wrapper {
    /* border: 1px dashed rgb(95, 207, 95); */
    display: block;
    margin: 10px;
}




.rank-icon {
    height: 50px;
    transition-duration: 0.1s;
    background-color: transparent;
    /* backdrop-filter: blur(25px) saturate(3); */
    
}

.rank-icon:hover {
    filter: drop-shadow(0 0 0.75rem rgb(255, 217, 0));
}

/* .rank-behind {
    position: relative;
    top: -25px;
    left: 25px;
    height: 5px;
    width: 5px;
    z-index: 99;
}

.rank-behind:hover {
    box-shadow: 0px 0px 400px #fff;
} */

.active-view {
    background-color: #64748b;
}